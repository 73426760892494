/* 
  This is the URL structure - https://{AzureADB2CHostname}/tfp/{Tenant}/{Policy}
  For more information, check this link - https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-net-aad-b2c-considerations
  Custom domain - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
  */

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_SignUpSignIn',
        forgotPassword: 'B2C_1_Reset',
        editProfile: 'B2C_1_EditProfile',
        signIn: 'B2C_1_SignIn'
    },
    authorities: {
        signUpSignIn: {
            authority:
                'https://login.tovera.com/b118909d-edc2-40e3-9af7-b3fd3e32289f/B2C_1_SignUpSignIn'
        },
        forgotPassword: {
            authority:
                'https://login.tovera.com/b118909d-edc2-40e3-9af7-b3fd3e32289f/B2C_1_Reset'
        },
        editProfile: {
            authority:
                'https://login.tovera.com/b118909d-edc2-40e3-9af7-b3fd3e32289f/B2C_1_EditProfile'
        },
        signIn: {
            authority:
                'https://login.tovera.com/b118909d-edc2-40e3-9af7-b3fd3e32289f/B2C_1_SignIn'
        }
    },
    authorityDomain: 'https://login.tovera.com'
};
