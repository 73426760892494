import React, {useContext, useState, useEffect} from 'react';
import useSWRwithToken from '../lib/useSWRwithToken';
import UserTerms from '../components/UserTerms';
import {API_ENDPOINTS} from '../helpers/constants';

const CurrentAccountContext = React.createContext();

export function useCurrentAccount() {
    return useContext(CurrentAccountContext);
}

export function CurrentAccountProvider({children}) {
    const [currentAccount, setCurrentAccount] = useState();
    const {data, error, isLoading} = useSWRwithToken(API_ENDPOINTS.users);

    // Set the current account after fetch.
    // User response is {value: []}
    // Set user to null to indicate a user exists, but does not have any attributes yet.
    // Attributes are currently asset_count and display_name and terms.
    useEffect(() => {
        if (error || isLoading || !data) return;
        setCurrentAccount(data.length ? data[0] : null);
    }, [data, error, isLoading]);

    function needToAcceptTerms(currentAccount) {
        let needToAcceptTerms = !!currentAccount;

        if (currentAccount?.terms) {
            let accepted_date = stringToDate(currentAccount.terms);
            let updated_at = stringToDate(
                process.env.NEXT_PUBLIC_TERMS_UPDATED_AT
            );

            needToAcceptTerms = accepted_date < updated_at;
        }

        return needToAcceptTerms;
    }

    function stringToDate(date_string) {
        var date_parts = date_string.split('-');
        return new Date(date_parts[0], date_parts[1] - 1, date_parts[2]);
    }

    return (
        <CurrentAccountContext.Provider value={currentAccount}>
            {needToAcceptTerms(currentAccount) && <UserTerms />}
            {children}
        </CurrentAccountContext.Provider>
    );
}
