import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {msalInstance} from '../pages/_app';
import {apiRequest} from '../lib/authConfig';

export default async function fetchWithToken(url, options = {}) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error(
            'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        );
    }

    const accessTokenRequest = {
        ...apiRequest,
        account: account
    };

    try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(
            accessTokenRequest
        );
        const token = accessTokenResponse.accessToken;

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        const requestOptions = {
            ...options,
            headers: headers
        };

        const response = await fetch(url, requestOptions);
        if (response.status >= 400 && response.status < 600) {
            // throw new Error('Bad response from server')
        }
        try {
            const result = await response.json();
            return result;
        } catch (e) {
            // We return text that invalid JSON sometimes. We silently catch errors here.
        }
    } catch (error) {
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
    }
}
