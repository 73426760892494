import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'

export default function Modal({open, children, onClose, title, showCloseIcon = true}) {
    if (!open) return null
    const contentRef = useRef(null);
    const canShowCloseIcon = showCloseIcon && !!onClose;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={contentRef}
                open={open}
                onClose={onClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                            {!!canShowCloseIcon && (
                                <div className="close-icon-row">
                                    <XIcon
                                        className="close-icon h5 w-5 text-gray-300"
                                        onClick={onClose}
                                    />
                                </div>
                            )}
                            <div className="title-container">
                                <div className="text-center">
                                    {title && (
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl leading-6 font-light text-black font-display mb-6"
                                        >
                                            {title}
                                        </Dialog.Title>
                                    )}
                                </div>
                            </div>
                            <div ref={contentRef}>{children}</div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
