import useSWR from 'swr';
import { useMsal } from '@azure/msal-react';
import fetchWithToken from './fetchWithToken';

export default function useSWRwithToken(url) {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { data, error } = useSWR(account ? url : null, fetchWithToken);

    return {
        data: data,
        error: error,
        isLoading: !error && !data,
    }
}
