import {b2cPolicies} from './b2cPolicies';

export const apiRequest = {
    scopes: [
        'https://tovera.onmicrosoft.com/152d8576-20ca-4062-ac2a-479e8b7b0789/read'
    ]
};

export const loginRequest = {
    scopes: ['openid', 'email', 'profile', 'offline_access'],
    forceRefresh: false
};

export const msalConfig = {
    auth: {
        clientId: '152d8576-20ca-4062-ac2a-479e8b7b0789',
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
};
