import { NavigationClient } from '@azure/msal-browser'
export class CustomNavigationClient extends NavigationClient {
    constructor(router) {
        super()
        this.router = router
    }

    async navigateInternal(url, options) {
        const relativePath = url.replace(window.location.origin, '')
        if (options.noHistory) {
            this.router.replace(relativePath)
        } else {
            this.router.push(relativePath)
        }
        return false
    }
}
