import React, {useContext, useState, useEffect} from 'react';
import {useRouter} from 'next/router';

import {MsalProvider} from '@azure/msal-react';
import {PublicClientApplication, EventType} from '@azure/msal-browser';

import {msalConfig} from '../lib/authConfig';
import {CustomNavigationClient} from '../lib/CustomNavigationClient';

import {AssetsProvider} from '../context/AssetsContext';
import {
    CurrentAccountProvider,
    useCurrentAccount
} from '../context/CurrentAccountContext';
import {LocaleProvider} from 'tovera-shared/context/LocaleContext';

import locales from '../locales/locales';

import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import '../styles/uploads.css';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export default function App({Component, pageProps}) {
    const router = useRouter();
    const navigationClient = new CustomNavigationClient(router);
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            <LocaleProvider locales={locales}>
                <CurrentAccountProvider>
                    <AssetsProvider>
                        <Component {...pageProps} />
                    </AssetsProvider>
                </CurrentAccountProvider>
            </LocaleProvider>
        </MsalProvider>
    );
}
