import {useState} from 'react';
import {ExternalLinkIcon} from '@heroicons/react/outline';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';

import Modal from '../components/Modal';
import fetchWithToken from '../lib/fetchWithToken';
import {useLocaleContextActions} from 'tovera-shared/context/LocaleContext';

const validationSchema = Yup.object().shape({
    acceptTerms: Yup.bool().oneOf(
        [true],
        'Accept terms, conditions, and privacy policies is required.'
    )
});

const TOVERA_LEGAL_EMAIL = 'legal@tovera.com';

export default function UserTerms() {
    const {translate} = useLocaleContextActions();
    const [isOpen, setIsOpen] = useState(true);

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        if (data.acceptTerms) {
            let year = new Date().getUTCFullYear();
            let date = new Date().getUTCDate();
            let month = new Date().getUTCMonth() + 1;
            fetchWithToken(`${process.env.NEXT_PUBLIC_API_URL_BASE}/users`, {
                method: 'POST',
                body: JSON.stringify({
                    terms: `${year}-${month}-${date}`
                })
            });
        }
        setIsOpen(false);
    }

    const lastUpdatedDate = new Date(
        process.env.NEXT_PUBLIC_TERMS_UPDATED_AT
    ).toDateString();

    return (
        <Modal
            open={isOpen}
            title={translate('user_terms.accept_terms_title')}
            onClose={() => setIsOpen(true)}
            onClickClose={false}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className="text-sm text-gray-500">
                    {translate('user_terms.please_accept_terms', {
                        lastUpdatedDate
                    })}
                </p>
                <ul className="space-y-4 text-sm p-6 bg-gray-50 rounded border-gray-border border mb-4 mt-6 font-medium">
                    <div className="tos-container mx-auto max-w-3xl py-6 sm:py-24 px-4 sm:px-6 ">
                        <h2 className="text-xl sm:text-2xl font-semibold">
                            {translate('user_terms.terms_title')}
                        </h2>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.terms_paragraph_one')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.terms_paragraph_two')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.contact_us_at')}
                            <a
                                href={`mailto:${TOVERA_LEGAL_EMAIL}`}
                                className="underline"
                            >
                                {TOVERA_LEGAL_EMAIL}
                            </a>
                            .
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.privacy_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.privacy_text')}
                            <a
                                href="https://tovera.com/privacy"
                                className="underline"
                            >
                                {translate('user_terms.privacy_link_text')}
                            </a>
                            .
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.ip_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.ip_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.notification_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_one')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_two')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_three')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_four')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_five')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_text_six')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.notification_contact_us')}
                            <a
                                href={`mailto:${TOVERA_LEGAL_EMAIL}`}
                                className="underline"
                            >
                                {TOVERA_LEGAL_EMAIL}
                            </a>
                            .
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.restrictions_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.restrictions_text_one')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.restrictions_contact_us')}
                            <a
                                href={`mailto:${TOVERA_LEGAL_EMAIL}`}
                                className="underline"
                            >
                                {TOVERA_LEGAL_EMAIL}
                            </a>
                            {translate('user_terms.restrictions_text_two')}
                            <p className="mt-3 text-gray-600">
                                {translate(
                                    'user_terms.restrictions_text_three'
                                )}
                            </p>
                            <p className="mt-3 text-gray-600">
                                {translate('user_terms.restrictions_text_four')}
                            </p>
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.third_party_links_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.third_party_links_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.acceptable_use_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.acceptable_use_text_one')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.acceptable_use_text_two')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.disclaimers_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            <span className="font-semibold">
                                {translate(
                                    'user_terms.disclaimers_investment_advice_header'
                                )}
                            </span>
                            {translate(
                                'user_terms.disclaimers_investment_advice_text'
                            )}
                        </p>
                        <p className="mt-3 text-gray-600">
                            <span className="font-semibold">
                                {translate(
                                    'user_terms.disclaimers_content_header'
                                )}
                            </span>
                            {translate('user_terms.disclaimers_content_text')}
                        </p>
                        <p className="mt-3 text-gray-600">
                            <span className="font-semibold">
                                {translate(
                                    'user_terms.disclaimers_warranty_header'
                                )}
                            </span>
                            {translate('user_terms.disclaimers_warranty_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.indemnification_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.indemnification_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate(
                                'user_terms.liability_limitation_header'
                            )}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.liability_limitation_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.severability_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.severability_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.complete_agreement_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.complete_agreement_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.termination_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.termination_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.governing_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.governing_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.dispute_resolution_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate(
                                'user_terms.dispute_resolution_contact_us'
                            )}
                            <a
                                href={`mailto:${TOVERA_LEGAL_EMAIL}`}
                                className="underline"
                            >
                                {TOVERA_LEGAL_EMAIL}
                            </a>
                            {translate(
                                'user_terms.dispute_resolution_text_one'
                            )}
                        </p>
                        <p className="mt-3 text-gray-600">
                            {translate(
                                'user_terms.dispute_resolution_text_two'
                            )}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.limitation_on_time_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.limitation_on_time_text')}
                        </p>
                        <h3 className="mt-3 text-xl font-medium">
                            {translate('user_terms.contact_header')}
                        </h3>
                        <p className="mt-3 text-gray-600">
                            {translate('user_terms.contact_contact_us')}
                            <a
                                href={`mailto:${TOVERA_LEGAL_EMAIL}`}
                                className="underline"
                            >
                                {TOVERA_LEGAL_EMAIL}
                            </a>
                            {translate('user_terms.contact_text')}
                        </p>
                    </div>
                    <li>
                        <a
                            href="https://tovera.com/privacy"
                            target="_blank"
                            className="text-gray-600 hover:text-black group flex"
                        >
                            <ExternalLinkIcon className="h-4 w-4 mr-2" />
                            {translate('user_terms.read_our_updated_policy')}
                        </a>
                    </li>
                </ul>
                <div className="flex flex-col">
                    <div className="flex items-center h-5">
                        <input
                            id="accept_terms"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            {...register('acceptTerms')}
                        />
                        <div className="ml-3 text-sm">
                            <label
                                htmlFor="accept_terms"
                                className="font-medium text-gray-700"
                            >
                                {translate('user_terms.i_accept')}
                            </label>
                        </div>
                    </div>
                    {errors && errors.acceptTerms && (
                        <div>
                            <p
                                className="mt-2 text-sm text-red-600"
                                id="title-error"
                            >
                                {errors.acceptTerms?.message}
                            </p>
                        </div>
                    )}
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black bg-primary outline-none focus:outline-none disabled:opacity-50"
                        >
                            {translate('user_terms.i_agree')}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
