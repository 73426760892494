import React, {useContext, useState, useEffect} from 'react';
import {isArray, reduce, map} from 'lodash';
import useSWRwithToken from '../lib/useSWRwithToken';
import {API_ENDPOINTS} from '../helpers/constants';
import {deserializeAssets} from 'tovera-shared/services/assetsService';
import {firstDateIsAfterSecond} from 'tovera-shared/helpers/dateHelper';

const AssetsContext = React.createContext();

export function useAssetContextState() {
    const {state} = useContext(AssetsContext);

    return {...state};
}

export function useAssetContextActions() {
    const {actions} = useContext(AssetsContext);

    return {...actions};
}

const sortByPublishedDate = (a, b) => {
    const isAfter = firstDateIsAfterSecond(a.publishedTime, b.publishedTime);
    return isAfter ? -1 : 1;
};

const sortByLastUpdate = (a, b) => {
    const isAfter = firstDateIsAfterSecond(a.lastUpdateTime, b.lastUpdateTime);
    return isAfter ? -1 : 1;
};

const updateAssets = (setAssets) => (data) => {
    let newAssets = data;

    if (isArray(data)) {
        newAssets = newAssets.sort(sortByLastUpdate);
    }

    setAssets(newAssets);
};

const unboundActions = {updateAssets};

export function AssetsProvider({children}) {
    const [assets, setAssets] = useState([]);
    const {data, error, isLoading} = useSWRwithToken(API_ENDPOINTS.assets);

    // Wait for data and reorder by the latest lastUpdateTime desc.
    useEffect(() => {
        if (!data || error || isLoading) {
            return;
        }

        const preparedAssets = deserializeAssets(data);
        updateAssets(setAssets)(preparedAssets);
    }, [data, error, isLoading]);

    const state = {assets, error, isLoading};
    const boundActions = reduce(
        unboundActions,
        (acc, actionFunction, actionKey) => {
            acc[actionKey] = actionFunction(setAssets);

            return acc;
        },
        {}
    );

    return (
        <AssetsContext.Provider value={{state, actions: boundActions}}>
            {children}
        </AssetsContext.Provider>
    );
}
