export const API_ENDPOINTS = {
    users: `${process.env.NEXT_PUBLIC_API_URL_BASE}/users`,
    assets: `${process.env.NEXT_PUBLIC_API_URL_BASE}/assets`,
    new: `${process.env.NEXT_PUBLIC_API_URL_BASE}/new`,
    newId: `${process.env.NEXT_PUBLIC_API_URL_BASE}/new_id`
};

export const DROPZONE_STATUS = {
    ERROR_UPLOAD_PARAMS: 'error_upload_params',
    EXCEPTION_UPLOAD: 'exception_upload',
    ERROR_UPLOAD: 'error_upload',
    ERROR_FILE_SIZE: 'error_file_size',
    GETTING_UPLOAD_PARAMS: 'getting_upload_params',
    UPLOADING: 'uploading',
    READY: 'ready',
    REMOVED: 'removed',
    ABORTED: 'aborted',
    HEADERS_RECEIVED: 'headers_received',
    DONE: 'done'
};
